import React from 'react'
import { Link } from "gatsby"

import { XIcon } from '@heroicons/react/solid'


export default function Banner() {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-[url('../images/raster-bg-alone-2x.png')] bg-cover bg-primary px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2 justify-center sm:justify-start">
        <p className="text-sm text-center leading-6 text-white">
          <strong className="font-semibold">NYSE® OPEN Venture Capital Unicorn® Index</strong>
          <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          Index  now live
        </p>
        <Link to="/unicorn-index" className="flex-none rounded-full px-3.5 py-1 text-sm font-semibold bg-accent text-white hover:bg-accent/80 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
          View Index <span aria-hidden="true">&rarr;</span>
        </Link>
      </div>
      <div className="flex flex-1 justify-end">

      </div>
    </div>
  )
}
