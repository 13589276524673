import React from "react";
import { Link } from "gatsby";
import Logo from "../images/logo.svg";
import { BsLinkedin } from "react-icons/bs";

export default function Footer() {
  return (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          <Link to="/privacy" className="text-gray-400 hover:text-gray-600">
            <span>Privacy Policy</span>
          </Link>
          <Link to="/contact" className="text-gray-400 hover:text-gray-600">
            <span>Contact</span>
          </Link>
          <a href="https://www.linkedin.com/company/openvcinc/about/">
            <BsLinkedin className="my-1 fill-gray-400 hover:fill-gray-600" />
          </a>
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-gray-400">
            &copy; {new Date().getFullYear()} OpenVC, Inc.&reg; All rights reserved.
          </p>
        </div>
        <div className="mt-8 md:mt-0 md:order-1 text-center text-base">
          <img className="h-8 w-auto sm:h-10 mx-auto" src={Logo} alt="OPEN" />
        </div>
      </div>
      <div className="max-w-7xl mx-auto text-sm mb-5 px-4">
        <p className="text-gray-400 text-justify">
          This information does not purport to be complete and no obligation to update or otherwise revise such information is being assumed. No person may treat this document as constituting either an offer to sell, or a solicitation of an offer to buy, any interest in this or any other fund. The information contained herein is not intended to provide, and should not be relied upon for accounting, legal or tax advice or investment recommendations.
          Any reproduction or distribution of this document, in whole or in part, or the disclosure of its contents, without the prior written consent of OpenVC, Inc. is prohibited.

          The information provided by OPEN and ICE and contained herein is subject to change without notice and does not constitute any form of representation, or undertaking. OPEN, ICE and its affiliates make no warranties whatsoever, either express or implied, as to merchantability, fitness for a particular purpose, or any other matter in connection with the information provided. Without limiting the foregoing, OPEN, ICE and its affiliates makes no representation or warranty that any information provided hereunder are complete or free from errors, omissions, or defects. The information in this analysis is for internal use only and redistribution of this information to third parties is expressly prohibited.

          Neither the analysis nor the information contained therein constitutes investment advice or an offer or an invitation to make an offer to buy or sell any securities or any options futures or other derivatives related to such securities. The information and calculations contained in this analysis have been obtained from a variety of sources including those other than OPEN and ICE ("we") and we do not guarantee their accuracy. Prior to relying on any information on this website and/or the execution of a security trade based upon such information, you are advised to consult with your broker or other financial representative to verify pricing information. There is no assurance that hypothetical results will be equal to actual performance under any market conditions. THE INFORMATION IS PROVIDED TO THE USERS "AS IS." NEITHER OPEN, ICE, NOR ITS AFFILIATES, NOR ANY THIRD PARTY DATA PROVIDER WILL BE LIABLE TO ANY USER OR ANYONE ELSE FOR ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS OF CAUSE, IN THE INFORMATION OR FOR ANY DAMAGES RESULTING THEREFROM. In no event shall OPEN, ICE or any of its affiliates, employees, officers, directors or agents of any such persons have any liability to any person or entity relating to or arising out of this information, analysis or the indices contained herein. 

          NYSE is a trademark of NYSE Group, Inc., and is used with permission under license by NYSE Group, Inc.
        </p>
      </div>
    </footer >
  );
}
