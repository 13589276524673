import React from "react"
import Footer from "./Footer";
import NavBarV2 from './NavBarV2';
import Banner from "./Banner";

interface LayoutProps {
    children: React.ReactNode
    transparentAtTop?: boolean
    hideFooter?: boolean
}

const Layout = ({ children, transparentAtTop = false, hideFooter = false }: LayoutProps) => {
    return (
        <div>
            <Banner></Banner>
            <NavBarV2 />
            <div className="bg-gray-50 relative">
                {children}
            </div>
            {hideFooter === false &&
                <Footer />
            }
        </div >
    )
}
export default Layout