import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

interface SEOProps {
    title: string,
    description?: string,
    image?: string,
}

const Seo = ({ title, description = "OPEN's mission is to make venture capital transparent, accessible, and efficient.", image = "/open-logo-frame.png" }: SEOProps) => {
    const { pathname } = useLocation()

    const siteUrl = "https://www.openvc.com";
    const fullTitle = title + ' - OPEN';
    const fullImage = `${siteUrl}${image}`;
    const url = `${siteUrl}${pathname}`;

    return (
        <Helmet>
            <title> {fullTitle}</title>
            <meta name="description" content={description} />
            <meta name="image" content={fullImage} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={fullTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={fullImage} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={fullTitle} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={fullImage} />
            <html lang="en" />
        </Helmet>
    )
}
export default Seo

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`